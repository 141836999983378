<template>
  <img alt="Lunch logo" src="./assets/knife-and-fork.png" class="logo" >
  <lunch></lunch>
</template>

<script>
import Lunch from './components/Lunch.vue'

export default {
  name: 'App',
  components: {
    Lunch
  }, 
  metaInfo() {
    return {
      title: 'Lunch tajm nu',
      meta: [
        {
          name: 'description',
          content: 'Din stöd för att bestämma dig vad du ska ha för lunch idag.'
        },
        {
          name: 'keywords',
          content: 'lunch, app, idag, lunchförslag, förslag för lunch'
        },
        {
          property: 'og:title',
          content: 'Lunch tajm nu'
        }, 
        {
          property: 'og:type',
          content: 'website'
        }

      ]
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.logo {
  width: 200px;
}

</style>
