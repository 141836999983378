<template>
    <p>Idag ska du äta</p>
    <div class="lunch">
        {{ lunch.suggestion }}
    </div>
    <p></p>
    <div class="lunch-buttons" v-if="lunch.on">
        <button @click="this.new()" class="btn no">Nä, inte idag</button>
        <button @click="this.showPlaces()" class="btn yes">Ja, det tar jag</button>
    </div>
    <div class="footer">a <a href="\\theshipyard.se">The Shipyard</a> service</div>
</template>

<script>


export default {
  name: 'Lunch',
    data() {
        return {
            alternatives: "tänker",
            lunch: {
                suggestion: "Funderar",
                on: true
                //list with lunch alternatives
            },
            
        }
    },
    metaInfo() {
        return {
            title: 'Lunch tajm nu',
            meta: [
                {
                    name: 'description',
                    content: 'Idag ska du äta {{ lunch.suggestion }}'
                },
                {
                    property: 'og:title',
                    content: 'Lunch tajm nu'
                }, 
                {
                    property: 'og:description',
                    content: 'Idag ska du äta {{ lunch.suggestion }}'
                },
                {
                    property: 'og:type',
                    content: 'website'
                }

            ]
        }

    },
    mounted() {
        //let ['pizza', 'kebab', 'burgare','sushi','buffe','baguette', 'korv', 'thai', 'kina'];
        // set this.alternatives to the array of alternatives
        let alternatives = ['pizza', 
        'kebab', 'burgare','sushi','buffe','baguette', 'korv', 'thai', 'kina', 'falafel',
        'grekiskt', 'husman', 'gryta', 'vietnamesisk', 'indiskt', 'italienskt', 'pasta', 'tapas',
        'mexikanskt', 'grill' ];
               
      
        this.lunch.suggestion = this.getSuggestion(alternatives);
            //this.alternatives = alternatives;

    },
    computed: {
        suggestion() {
            return this.getSuggestion();
        } 
    },
    methods: {
        
        getSuggestion(alternatives) {
            //wait until alternatives is an array
            console.log(alternatives);
            this.alternatives = alternatives;
            //check if alternatives is an array
            if (alternatives instanceof Array) {
                if (alternatives.length === 0) {
                    this.lunch.on = false;
                    return "Sorry, har inte flera förslag. Det blir ingen lunch idag.";
                }
                //get random index
                let index = Math.floor(Math.random() * alternatives.length);
                //return suggestion
                this.alternatives = alternatives;
                return alternatives[index];
            } 
        },
        new() {
            //remove suggestion from alternatives
            this.alternatives.splice(this.alternatives.indexOf(this.lunch.suggestion), 1);
            this.lunch.suggestion = this.getSuggestion(this.alternatives);
            return this.lunch.suggestion;
        },
        showPlaces() {
            //store the suggestion in local storage
            localStorage.setItem(this.lunch.suggestion, new Date());
            //google search link
            let link = "https://google.se/search?q=" + this.lunch.suggestion+" restaurang";
            //open link in new tab
            window.open(link, '_blank');
        }
    }


}
</script>

<style>

.lunch {
    font-size: 2em;
    font-weight: bold;
    color: #2c3e50;
}
.btn {  
    background-color: #2c3e50;
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}
.lunch-buttons {
    margin-top: 20px;
}
.no {
    background-color: #e74c3c;
    color: #ecf0f1;
}
.yes {
    background-color: #27ae60;
    color: #ecf0f1;
}
.footer {
    align-content: left;
    text-align: left;
    position: fixed;
    left: 10;
    bottom: 0;
    width: 100%;
    font-size: 0.8em;
    color: #bdc3c7;
}
.footer a {
    color: #bdc3c7;
}
</style>