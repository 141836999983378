import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { plugin as VueMetaPlugin } from 'vue-meta'
//import router  from "vue-router";

//import runtime from "serviceworker-webpack-plugin/lib/runtime";



//createApp(App).use(router).mount('#app')
createApp(App).use(VueMetaPlugin).mount('#app')
